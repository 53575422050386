import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import './mixins';
import router from './router'
import store from './store'
import App from './App.vue'
const moment = require('moment')
Vue.use(require('vue-moment'), {
  moment
})
// Global Components
import './global-components'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import './plugins/firebase'
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(DatePicker)
import 'firebase/messaging'
import VueSweetalert2 from 'vue-sweetalert2';
// // Initialize Firebase
Vue.use(VueSweetalert2);
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
