import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch} from '../../config'

export default {
    namespaced: true,
    state: {
        total_treatment: []
    },
    mutations: {
        setTotalTreatment(state, total_treatment) {
            state.total_treatment = total_treatment
        },

    },
    actions: {
        // LOGIN
        async findTreatment({commit}) {
            const response = await requestFetch(`/api/treatment/all`, 'GET');
            return response.treatments;

        },
        async findTreatmentAppointment({commit},id) {
            const response = await requestFetch(`/api/treatment/treatment_appointment/${id}`, 'GET');
            return response.treatments;

        },
        async findTreatmentAppointmentCustomer({commit},id) {
            const response = await requestFetch(`/api/treatment/treatment_appointment_customer/${id}`, 'GET');
            return response.treatments;

        },
        async findTreatmentDate({commit},appoint_date) {
            const response = await requestFetch(`/api/treatment/all_date`, 'POST', {appoint_date: appoint_date});
            return response.treatments;

        },
        async findTreatmentValidate({commit},appoin) {
            const response = await requestFetch(`/api/treatment/all_validate/${appoin}`, 'GET');
            return response.treatments;

        },
        async verifyCheck({commit},data) {
            const response = await requestFetch(`/api/treatment/check_exit/${data}`, 'GET');
            return response.msg;

        },
        setTotalTreatment({commit}, total_treatment) {
            commit('setTotalTreatment', total_treatment)
        },
        async validateCount({commit}) {
            const response = await requestFetch(`/api/treatment/verify_count/${store.getters['auth/getUser'].HrDepartment.name}`, 'GET');
            commit('setTotalTreatment', response.count)
        },
        async sendCheck({commit},data) {
            const response = await requestFetch(`/api/treatment/create_exit/`, 'POST', data);
            if (response){
                return true
            }else{
                return false
            }
        },
        async updateCheck({commit},{id, data}) {

            const response = await requestFetch(`/api/treatment/update_exit/${id}`, 'PUT', data);
            if (response){
                return true
            }else{
                return false
            }
        },
        async finById({commit},data) {
            const response = await requestFetch(`/api/treatment/treatment_id/${data}`, 'GET');
            return response.msg
        },
        async finByIdCheck({commit},data) {
            const response = await requestFetch(`/api/treatment/treatment_id_check/${data}`, 'GET');
            return response.check
        },
        async findProduct({commit}) {
            const response = await requestFetch(`/api/product/all`, 'GET');
            return response.products;

        },

        async createTreatmentSend({commit},data) {
            const response = await requestFetch(`/api/treatment/lines`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return  false
            }
        },

        async findImgVerify({commit},data) {
            const response = await requestFetch(`/api/treatment/img_type/${data.id}`, 'GET');
            if (parseInt(data.number ) === 1 ){

                return response.img.treatment_file
            }else if (parseInt(data.number ) === 2 ){
                return response.img.treatment_file2
            }else if (parseInt(data.number ) === 3){
                return response.img.treatment_file3
            }else{
                return response.img.treatment_file4
            }

        },
        async findImgVerifyModal({commit},data) {
            const response = await requestFetch(`/api/treatment/img_type/${data}`, 'GET');
            return response.img

        },
        async updateTreatment({commit}, {id,data}) {
            const response = await requestFetch(`/api/treatment/update/${id}`, 'PUT', data);
            if (response.msg){
                return true
            }else{
                return  false
            }
        },
        async getOneTreatment({commit},data) {
            const response = await requestFetch(`/api/treatment/one/${data}`, 'GET');
            return response.treatment
        },
        async getOneTreatmentNoFiles({commit},data) {
            const response = await requestFetch(`/api/treatment/one_no_files/${data}`, 'GET');
            return response.treatment
        },
        async getStockPicking({commit},data) {
            const response = await requestFetch(`/api/treatment/stock_picking/${data}`, 'GET');
            if (response.order){
                return response.order
            }else{
                return false
            }

        },
    },
    getters: {
        getTotalTreatment(state) {
            return state.total_treatment;
        },
    }

}
