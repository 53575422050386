import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                pageTitle: 'Home',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
                authenticate_required: true
            },
        },
        {
            path: '/pacientes',
            name: 'Patients',
            component: () => import('../views/Patients/PatientList'),
            meta: {
                pageTitle: 'Pacientes',
                breadcrumb: [
                    {
                        text: 'Pacientes',
                        active: true,
                    },
                ],
                authenticate_required: true
            },
        },
        {
            path: '/nueva/:type/:user',
            name: 'NewAppoitment',
            component: () => import('../views/Appointments/NewAppoitment'),
            meta: {
                pageTitle: 'Nueva cita',
                breadcrumb: [
                    {
                        text: 'Cita',
                        active: true,
                    },
                ],
                authenticate_required: true
            },
        },
        {
            path: '/detalles_cita/:id',
            name: 'UpdateAppoitment',
            component: () => import('../views/Appointments/UpdateAppoitment'),
            meta: {
                pageTitle: 'Editar cita',
                breadcrumb: [
                    {
                        text: 'Cita',
                        active: true,
                    },
                ],
                authenticate_required: true
            },
        },
        {
            path: '/paciente/:id',
            name: 'Patient',
            component: () => import('../views/Patients/DetailPatient'),
            meta: {
                pageTitle: 'Inicio',
                breadcrumb: [
                    {
                        text: 'Paciente',
                        active: true,
                    },
                ],
                authenticate_required: true
            },
        },
        {
            path: '/nuevo_paciente',
            name: 'AddPatient',
            component: () => import('../views/Patients/AddPatient'),
            meta: {
                pageTitle: 'Inicio',
                breadcrumb: [
                    {
                        text: 'Agregar Paciente',
                        active: true,
                    },
                ],
                authenticate_required: true
            },
        },
        {
            path: '/tratemientos',
            name: 'ListTreatment',
            component: () => import('../views/Treatment/ListTreatment'),
            meta: {
                pageTitle: 'Lista de tratamientos',
                breadcrumb: [
                    {
                        text: 'Tratamientos',
                        active: true,
                    },
                ],
                authenticate_required: true
            },
        },
        {
            path: '/tratemiento/:id',
            name: 'DetailTreatment',
            component: () => import('../views/Treatment/DetailTreatment'),
            meta: {
                pageTitle: 'Tratamiento',
                breadcrumb: [
                    {
                        text: 'Ver tratamiento',
                        active: true,
                    },
                ],
                authenticate_required: true
            },
        },
        {
            path: '/crear_tratamiento/:id',
            name: 'CreateTreatment',
            component: () => import('../views/Treatment/CreateTreatment'),
            meta: {
                pageTitle: 'Tratamiento',
                breadcrumb: [
                    {
                        text: 'Crear tratamiento',
                        active: true,
                    },
                ],
                authenticate_required: true
            },
        },
        {
            path: '/citas',
            name: 'AppointmentList',
            component: () => import('../views/Appointments/AppointmentList'),
            meta: {
                pageTitle: 'Citas',
                breadcrumb: [
                    {
                        text: 'Citas',
                        active: true,
                    },
                ],
                authenticate_required: true
            },
        },
        {
            path: '/salas',
            name: 'CalendarPlace',
            component: () => import('../views/Places/CalendarPlace'),
            meta: {
                pageTitle: 'Salas',
                breadcrumb: [
                    {
                        text: 'Calendario Salas',
                        active: true,
                    },
                ],
                authenticate_required: true
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})
router.beforeEach(async (to, from, next) => {
    if (to.meta.authenticate_required) {
        await store.dispatch('auth/userme');
        if (store.getters["auth/getToken"] === "") {
            next("/login");
        } else {
            next();
        }
    } else {
        next();
    }


    if (to.path === '/login' && store.getters["auth/getToken"] !== "") {
        next("/");
    } else {
        next();
    }

});

export default router
